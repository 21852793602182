@font-face {
  font-family: 'GTAmerica';
  src: local('GTAmerica'), url(./fonts/GTAmerica-ExtRg.1a5fe35e.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
html {
  background:rgb(255, 255, 255) none repeat scroll 0% 0%
}
body {
  margin: 0;
  font-family: 'GTAmerica', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

